<template>
  <b-card
    title="My Applications"
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="appliedJobs"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(jobID)="data">
        <b-badge
          pill
          :variant="`light-secondary`"
          class="text-capitalize"
        >
          {{ findJob(data.value).positions }}
        </b-badge>
        {{ findJob(data.value).post || 'Post Unavailable' }}</template>
      <template #cell(createdAt)="data">
        {{ formatDate(data.value) }}
      </template>

      <template #cell(id)="data">
        <b-badge
          pill
          :variant="`light-${getStatus(data.value).color}`"
          class="text-capitalize"
        >
          {{ getStatus(data.value).status }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BTable,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
} from 'bootstrap-vue'

import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardBody,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'jobID', label: 'Post', sortable: true },
        { key: 'createdAt', label: 'Date Applied', sortable: true },
        { key: 'id', label: 'Status', sortable: true },
        { key: 'id', label: 'Actions' },
      ],
      items: [
        {
          id: 1,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/10-small.png'),
          full_name: "Korrie O'Crevy",
          post: 'Nuclear Power Engineer',
          email: 'kocrevy0@thetimes.co.uk',
          city: 'Krasnosilka',
          start_date: '09/23/2016',
          salary: '$23896.35',
          age: '61',
          experience: '1 Year',
          status: 2,
        },
      ],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    jobs() {
      return this.$store.state.jobs.jobs || []
    },
    appliedJobs() {
      const aj = this.$store.state.jobs.jobsApplied || []
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalRows = aj.length
      return aj
    },
    departments() {
      return this.$store.state.departments.departments || []
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.appliedJobs.length
  },
  created() {
    this.$store.dispatch('jobs/fetchAppliedJobs')
  },
  methods: {
    formatDate,
    getStatus(id) {
      const application = this.appliedJobs.find(e => e.id === id)
      const status = {
        status: 'pending',
        color: 'secondary',
      }
      if (application.awardedJob) {
        status.status = 'Awarded Job'
        status.color = 'success'
      } else if (application.interviewed) {
        status.status = application.interviewResult
          ? 'Interview Passed'
          : 'Interview Failed'
        status.color = application.interviewResult ? 'info' : 'danger'
      } else if (application.shortListed) {
        status.status = 'Shortlisted'
        status.color = 'info'
      } else if (application.processed) {
        status.status = 'Processed'
        status.color = 'warning'
      }
      return status
    },
    findJob(id) {
      return this.jobs.find(e => e.id === id)
    },
    findDepartment(id) {
      return this.departments.find(e => e.id === id)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
